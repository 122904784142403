<template>
    <div>
        <a-input v-if="isShowInput" v-model="keyword" style="margin-bottom: 20px" placeholder="请输入详细地址" :allow-clear="true" @focus="onSearch" @change="onChange" />
        <p v-if="isShowSite" style="margin-bottom: 20px">
            位置信息：{{ site }}
        </p>
        <baidu-map :center="city">
            <bm-view class="map" />
            <bm-local-search :keyword="keyword" :panel="isShow" :auto-viewport="true" :location="location" @searchcomplete.once="onSearch" @infohtmlset="onSelect" />
        </baidu-map>
    </div>
</template>
<script>
import '@/utils/loadBMap'
export default {
    name: 'Map',
    props: {
        site: {
            type: String,
            default: ''
        },
        isShowInput: {
            type: Boolean,
            default: true
        },
        isShowSite: {
            type: Boolean,
            default: true
        },
        searchVal: {
            type: String,
            default: ''
        }
    },
    data () {
        return {
            flag: false,
            // 输入框输入的文字
            keyword: this.searchVal,
            // 默认城市
            city: '重庆',
            dataSource: [],
            location: '',
            // 是否显示检索面板
            isShow: false
        }
    },
    watch: {
        searchVal(val) {
            this.keyword = val
        }
    },
    methods: {
        // 搜索及获取焦点时触发
        onSearch() {
            if(this.flag) {
                this.isShow = true
            } else {
                this.flag = true
            }
        },
        // 选择地址
        onSelect(val) {
            this.isShow = false
            this.$emit('onSelect', val)
        },
        onChange() {
            this.$emit('onChange')
        }
    }
}
</script>
<style lang="scss" scoped>
.map {
    width: 100%;
    height: 200px;
}
</style>
