var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isShowInput
        ? _c("a-input", {
            staticStyle: { "margin-bottom": "20px" },
            attrs: { placeholder: "请输入详细地址", "allow-clear": true },
            on: { focus: _vm.onSearch, change: _vm.onChange },
            model: {
              value: _vm.keyword,
              callback: function ($$v) {
                _vm.keyword = $$v
              },
              expression: "keyword",
            },
          })
        : _vm._e(),
      _vm.isShowSite
        ? _c("p", { staticStyle: { "margin-bottom": "20px" } }, [
            _vm._v(" 位置信息：" + _vm._s(_vm.site) + " "),
          ])
        : _vm._e(),
      _c(
        "baidu-map",
        { attrs: { center: _vm.city } },
        [
          _c("bm-view", { staticClass: "map" }),
          _c("bm-local-search", {
            attrs: {
              keyword: _vm.keyword,
              panel: _vm.isShow,
              "auto-viewport": true,
              location: _vm.location,
            },
            on: {
              "~searchcomplete": function ($event) {
                return _vm.onSearch.apply(null, arguments)
              },
              infohtmlset: _vm.onSelect,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }